export const Strings = {
  AMEX: "AMEX",
  BALANCE: "Saldo",
  BANK_SLIP: "Boleto",
  BANK_SLIP_COPY_CODE_TEXT: "Copiar Código ",
  BANK_SLIP_INFO_MESSAGE: "Aqui está seu boleto",
  BANK_SLIP_SUCESS_SUBTITLE:
    "O processamento do pagamento pode levar até 3 dias úteis",
  BANK_SLIP_SUCESS_SUBTITLE_2:
    "Você receberá a confirmação do pagamento por e-mail.",
  BANK_SLIP_SUCESS_TITLE: "Boleto gerado com sucesso!",
  BR_CURRENCY_SYMBOL: "R$",
  BROKER: "Corretora",
  CAR_INSURANCE: "Seguro auto",
  CASH_PAYMENT: "Pagamento à vista",
  CONTINUE: "Continuar",
  CONTRACTED_VALUE: "Valor contratado",
  COPIED_TO_CLIPBOARD: "Copiado para a área de transferência",
  CREDIT_CARD: "Cartão de Crédito",
  CREDIT_CARD_NUMBER: "Número do cartão",
  CREDIT_CARD_PAYMENT_SUCESS_MESSAGE_SUBTITLE:
    "Você receberá a confirmação no email",
  CREDIT_CARD_PAYMENT_SUCESS_MESSAGE_TITLE: "O pagamento foi realizado",
  CREDIT_CARD_PAYMENT_SUCESS_SUBTITLE: "Você concluiu o processo de pagamento",
  CREDIT_CARD_PAYMENT_SUCESS_TITLE: "Tudo certo!",
  CREDIT_CARD_PAYMENT_SCHEDULED_SUCESS_MESSAGE_SUBTITLE:
    "Você receberá a confirmação no email",
  CREDIT_CARD_PAYMENT_SCHEDULED_SUCESS_MESSAGE_TITLE:
    "O pagamento foi agendado",
  CREDIT_CARD_PAYMENT_SCHEDULED_SUCESS_SUBTITLE:
    "Você concluiu o processo de agendamento do pagamento",
  CREDIT_CARD_PAYMENT_SCHEDULED_SUCESS_TITLE: "Tudo certo!",
  CREDIT_CARD_PAYMENT_ERROR_MESSAGE:
    "Parece que houve um problema com o pagamento. Verifique as informações e tente novamente.",
  CREDIT_CARD_PAYMENT_ERROR_BUTTON_MESSAGE: "Tentar novamente",
  CREDIT_CARD_PAYMENT_ERROR_SUBTITLE:
    "Você não concluiu o processo de pagamento",
  CREDIT_CARD_PAYMENT_ERROR_TITLE: "Erro no pagamento!",
  CREDIT_CARD_PAYMENT_PENDING_MESSAGE:
    "O pagamento está sendo processado pelo gateway de pagamento.",
  CREDIT_CARD_PAYMENT_PENDING_SUBTITLE: "O pagamento está sendo processado.",
  CREDIT_CARD_PAYMENT_PENDING_TITLE: "Pagamento em processamento!",
  CPF: "CPF",
  CPF_HOLDER: "CPF do titular",
  DATE_OF_BIRTH: "Data de nascimento",
  DATE_OF_BIRTH_PLACEHOLDER: "DD/MM/AAAA",
  DEBIT: "Débito",
  DINERS: "DINERS",
  DISCOVER: "DISCOVER",
  EMPTY: "",
  EXPIRATION_DATE: "Data de vencimento",
  EXPIRATION_DATE_PLACEHOLDER: "MM/AAAA",
  FULL_NAME: "Nome completo",
  HELP: "Ajuda",
  INVALID_CARD_NUMBER: "O cartão é inválido",
  INVALID_DATE_OF_BIRTH: "Data de nascimento inválido",
  INVALID_DOCUMENT: "CPF inválido",
  INVALID_EXPIRATION_DATE: "Data de vencimento inválido",
  INVALID_HOLDER: "Nome inválido",
  INVALID_SECURITY_CODE: "Cód. de segurança inválido",
  LOADING: "Por favor, aguarde o carregamento.",
  LOYALTY_POINTS: "Pontos",
  MAKE_PAYMENT: "Realizar pagamento",
  MASTERCARD: "MASTERCARD",
  NO_INTERNET_CONNECTION: "Você está sem conexão com a internet",
  NUPAY: "NUPAY",
  NUMBER_OF_PLOTS: "Número de parcelas",
  NUMBER_PLACEHOLDER: "0000 0000 0000 0000",
  OPEN_FINANCE: "Open Finance",
  OPEN_FINANCE_INFO_MESSAGE:
    "Selecione seu banco e autorize o pagamento no site ou app:",
  OPEN_FINANCE_REVIEW_MESSAGE: "REVISE AS INFORMAÇÕES",
  OPEN_FINANCE_AUTHORIZE_PAYMENT: "Autorizar pagamento",
  OPEN_FINANCE_AUTHORIZATION_TITLE: "Autorização de pagamento",
  OPEN_FINANCE_AUTHORIZATION_SUBTITLE:
    "Conclua o processo de autorização do pagamento",
  OPEN_FINANCE_AUTHORIZATION_REDIRECT_MESSAGE:
    "Estamos te redirecionando para a janela ou aplicativo:",
  OPEN_FINANCE_PAYMENT_INFO:
    "Ao autorizar pagamento, a janela ou aplicativo do banco será aberta e você deve confirmar pelo ambiente seguro do Open Finance.",
  PAGE_NOT_FOUND: "Página não encontrada",
  PAYMENT: "Pagamento",
  PIX: "Pix",
  PIX_COPY_CODE_TEXT: "Copiar Código Pix",
  PIX_INFO_MESSAGE: "Por favor, conclua o pagamento antes do tempo finalizar",
  PIX_QRCODE_INFO_MESSAGE: "Utilize o código abaixo no app do seu banco:",
  PRIVACY_POLICY: "Política de privacidade",
  PRODUCT: "Produto",
  PROPOSAL_NUMBER: "Número da proposta",
  REQUIRED: "O campo é obrigatório",
  SECURITY_CODE: "Cód. de segurança",
  SECURITY_CODE_PLACEHOLDER: "000",
  SELECT_A_PAYMENT_METHOD: "Selecione um meio de pagamento",
  SOMETHING_WENT_WRONG: "Aconteceu algo de errado",
  START_PAYMENT: "Começar o pagamento",
  SUMMARY: "Resumo",
  TITULAR: "Titular",
  TIMEOUT_TITLE: "O tempo acabou!",
  TIMEOUT_SUBTITLE: "Você não concluiu o processo de pagamento",
  TIMEOUT_MESSAGE_TITLE: "O tempo acabou",
  TIMEOUT_MESSAGE_SUBTITLE:
    "O tempo para realizar o pagamento pelo Open Finance acabou, mas você pode tentar novamente.",
  TIMEOUT_TRY_AGAIN: "Tentar novamente.",
  USE_THE_SAME_NAME: "Utilize o mesmo nome impresso no cartão",
  VEHICLE_LICENSE_PLATE: "Placa do Veículo",
  VISA: "VISA",
  EXPIRED_PAYMENT_TITLE: "Pagamento Expirado",
  EXPIRED_PAYMENT_SUBTITLE: "O prazo para pagamento expirou.",
  EXPIRED_PAYMENT_MESSAGE:
    "Você precisará iniciar uma nova tentativa de pagamento.",
  TRY_AGAIN: "Tentar novamente",
};

export default Strings;
