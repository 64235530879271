const RoutesPath = {
  HOME: "/",
  PIX: "/Pix",
  PIX_SUCCESS: "/Pix/Success",
  PIX_ERROR: "/Pix/Error",
  CREDIT_CARD: "/CreditCard",
  CREDIT_CARD_SUCCESS: "/CreditCard/Success",
  CREDIT_CARD_SCHEDULED: "/CreditCard/Scheduled",
  CREDIT_CARD_ERROR: "/CreditCard/Error",
  CREDIT_CARD_PENDING: "/CreditCard/Pending",
  BANK_SLIP: "/BankSlip",
  BANK_SLIP_SUCCESS: "/BankSlip/Success",
  PAYMENT_METHODS: "/PaymentMethods",
  OPEN_FINANCE: "/OpenFinance",
  OPEN_FINANCE_REVIEW: "/OpenFinance/Review",
  OPEN_FINANCE_AUTHORIZATION: "/OpenFinance/Authorization",
  OPEN_FINANCE_SUCCESS: "/OpenFinance/Success",
  OPEN_FINANCE_TIMEOUT: "/OpenFinance/Timeout",
  EXPIRED_PAYMENT: "/Expired",
};

export default RoutesPath;
