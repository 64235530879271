import React from "react";
import { RoutesPath, Strings } from "src/constants";
import { ErrorIcon } from "src/constants/Icons";
import LayoutCard from "src/components/shared/LayoutCard";
import PaymentTimeline from "src/components/shared/PaymentTimeline";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { Button, PaymentSummaryCard } from "src/components";
import { useNavigate, useParams } from "react-router-dom";

const PixPaymentError: React.FC = () => {
  const paymentInfo = useCheckoutDataContext();
  const navigate = useNavigate();
  const params = useParams<OrderSubscriptionParamsType>();
  const token = params.token as string;

  return (
    <main className="flex grow flex-col items-center bg-color-neutral-base-2">
      <div className="grid max-w-screen-xl items-start gap-4 rounded-lg p-4 md:grid-flow-col md:grid-cols-[auto_1fr] md:grid-rows-[auto_1fr] lg:px-6">
        <PaymentTimeline />
        <LayoutCard>
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="text-color-neutral-strong text-2xl font-semibold leading-[20px]">
              {Strings.CREDIT_CARD_PAYMENT_ERROR_TITLE}
            </div>
            <p className="items-start">
              {Strings.CREDIT_CARD_PAYMENT_ERROR_SUBTITLE}
            </p>
          </div>
          <div className="grid grid-cols-[auto_1fr] items-center gap-x-4 rounded-lg bg-color-status-danger-soft p-4">
            <ErrorIcon className="row-span-2 text-color-status-danger-strong" />
            <p className="items-start font-bold text-color-status-danger-strong">
              {Strings.CREDIT_CARD_PAYMENT_ERROR_MESSAGE}
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              text={Strings.CREDIT_CARD_PAYMENT_ERROR_BUTTON_MESSAGE}
              onClick={() => {
                navigate(`/${token}${RoutesPath.PAYMENT_METHODS}`);
              }}
              position="left"
              variant="secondary"
              size="small"
            />
          </div>
        </LayoutCard>
        <div className="md:row-span-2">
          <PaymentSummaryCard order={paymentInfo} />
        </div>
      </div>
    </main>
  );
};

export default PixPaymentError;
