import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Strings } from "src/constants";
import { Timer } from "src/constants/Icons";
import { Button, PaymentSummaryCard } from "src/components";
import LayoutCard from "src/components/shared/LayoutCard";
import PaymentTimeline from "src/components/shared/PaymentTimeline";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { RoutesPath } from "src/constants";

const ExpiredPayment: React.FC = () => {
  const paymentInfo = useCheckoutDataContext();
  const navigate = useNavigate();
  const params = useParams<OrderSubscriptionParamsType>();
  const token = params.token as string;

  return (
    <main className="flex grow flex-col items-center bg-color-neutral-base-2">
      <div className="grid max-w-screen-xl items-start gap-4 rounded-lg p-4 md:grid-flow-col md:grid-cols-[auto_1fr] md:grid-rows-[auto_1fr] lg:px-6">
        <PaymentTimeline />
        <LayoutCard>
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="text-color-neutral-strong text-2xl font-semibold leading-[20px]">
              {Strings.EXPIRED_PAYMENT_TITLE}
            </div>
            <p className="items-start">
              {Strings.EXPIRED_PAYMENT_SUBTITLE}
            </p>
          </div>
          <div className="grid grid-cols-[auto_1fr] items-center gap-x-4 rounded-lg bg-color-status-danger-soft p-4">
            <Timer className="row-span-2 text-color-status-danger-strong" />
            <p className="items-start font-bold text-color-status-danger-strong">
              {Strings.EXPIRED_PAYMENT_MESSAGE}
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              text={Strings.TRY_AGAIN}
              onClick={() => navigate(`/${token}${RoutesPath.PAYMENT_METHODS}`)}
              position="left"
              variant="secondary"
              size="small"
            />
          </div>
        </LayoutCard>
        <div className="md:row-span-2">
          <PaymentSummaryCard order={paymentInfo} />
        </div>
      </div>
    </main>
  );
};

export default ExpiredPayment; 